/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'

import App from './App'
import { GlobalStyles } from 'styles/global'
import Unleash from 'unleash'
import { Metrics } from '@sanardigital/analytics-tracker'
import { Monitoring } from '@sanardigital/monitoring-wrapper'

ReactDOM.render(
    <React.StrictMode>
        <Unleash>
            <Router basename='v1'>
                <GlobalStyles />
                <App />
            </Router>
        </Unleash>
    </React.StrictMode>,
    document.getElementById('root')
)

Monitoring.init(`sso-frontend-${window.env.ENVIRONMENT}`)
if (window.env.ENVIRONMENT !== 'development') {
    Metrics.init()
}
